export default class ChangeRequestService {
  constructor($http, $log) {
    this.http = $http;
    this.log = $log;
  }

  getChangesByStatus(status) {
    return this.http({
      method: 'GET',
      url: `/api/v3/cr?status=${status}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  getChangesByUser(user) {
    return this.http({
      method: 'GET',
      url: `/api/v3/cr?user=${user}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  getChangesByUserAndStatus(user, status) {
    if (!user || !status) {
      return null;
    }
    return this.http({
      method: 'GET',
      url: `/api/v3/cr/?user=${user}&status=${status}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  getChangeRequest(id) {
    return this.http({
      method: 'GET',
      url: `/api/v3/cr/${id}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  saveAsChangeRequest(data) {
    return this.http({
      method: 'POST',
      url: `/api/v3/cr/save`,
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  uploadCsv(csvfile) {
    const formdata = new FormData();
    formdata.append('csvfile', new Blob([csvfile], {type: 'text/csv'}));
    return this.http({
      method: 'POST',
      url: `/api/v3/cr/upload`,
      headers: {'Content-Type': undefined},
      data: formdata,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  setImported(id) {
    return this.http({
      method: 'PATCH',
      url: `/api/v3/cr/imported/${id}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  setDiscarded(id) {
    return this.http({
      method: 'PATCH',
      url: `/api/v3/cr/discarded/${id}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  setPending(id) {
    return this.http({
      method: 'PATCH',
      url: `/api/v3/cr/pending/${id}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }
}
