import {getDateString, setDateFromString} from '../lib/utils';

export default function Pending() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: PendingController,
    template: require('./pending.html'),
    scope: {},
  };
}

class PendingController {
  constructor(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    ChangeRequestService,
    ImportService,
    HierarchyService
  ) {
    this.data = $stateParams.data;
    this.id = $stateParams.id;
    this.status = $stateParams.status;
    this.previous = $stateParams.previous;
    this.state = $state;
    this.diff = this.data ? this.data.diff : null;
    this.admin = $rootScope.ausr;
    this.changeRequestService = ChangeRequestService;
    this.importService = ImportService;
    this.dataKind = {E: 'EDIT', N: 'NEW', D: 'DELETE'};
    this.hierarchyService = HierarchyService;
    this.date = new Date();
    this.minDate();
    this.data.validationResults.forEach((ou) => {
      ou.errors = ou.results.filter((item) => {
        return !!item.error;
      });
      ou.warnings = ou.results.filter((item) => {
        return !!item.warning;
      });
    });
    this.errors = this.data.validationResults.filter(
      (ou) => ou.errors.length > 0
    );
    this.warnings = this.data.validationResults.filter(
      (ou) => ou.warnings.length > 0
    );
  }

  minDate() {
    const that = this;
    let minDate = new Date();
    this.hierarchyService.getChanges().then((changes) => {
      changes.forEach((change) => {
        const m = setDateFromString(change.validFrom);
        if (m.valueOf() > minDate.valueOf()) {
          minDate = m;
        }
      });
      that.minDate = minDate;
      if (that.date.valueOf() > that.minDate.valueOf()) {
        that.date = that.minDate;
      }
    });
  }

  import() {
    const data = Object.assign({}, this.data, {
      comment: this.comment,
      date: this.date,
    });
    this.importService.postData(data).then(() => {
      this.changeRequestService.setImported(this.id).then(() => {
        this.state.go('overview', {
          date: getDateString(this.date),
        });
      });
    });
  }

  discard() {
    this.changeRequestService.setDiscarded(this.id).then(() => {
      this.state.go('overview', {date: getDateString(this.date)});
    });
  }
}
