import {getDateString, getDateAndTimeStr} from '../lib/utils';

export default class HierarchyService {
  constructor($http, $log) {
    this.http = $http;
    this.log = $log;
  }

  getFull(date) {
    this.date = date || getDateString(new Date());
    return this.http({
      method: 'GET',
      url: `/api/v3/hierarchy/default?populate=true&at=${this.date}`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  getChanges() {
    return this.http({method: 'GET', url: '/api/v3/commit'})
      .then((response) => {
        if (response && response.data) {
          response.data.forEach((commit) => {
            // Format: 'YYYY-MM-DD hh:mm:ss'
            commit.timestamp = getDateAndTimeStr(new Date(commit.timestamp));
          });
          return response.data;
        }
        this.log.warn('invalid response');
        return [];
      })
      .catch((err) => {
        this.log.error(err);
        return null;
      });
  }

  removeChange(change) {
    return this.http({
      method: 'DELETE',
      url: `/api/v3/ou/${change.validFrom}`,
    }).then(() => {
      return this.getChanges();
    });
  }
}
