export default class AppService {
  constructor($http, $log) {
    this.http = $http;
    this.log = $log;
    this.busy = false;
    this.subs = [];
    this.timer = setInterval(() => {
      this.http({
        method: 'GET',
        url: '/api/v3/hierarchy/busy',
      })
        .then((response) => {
          if (response && response.data) {
            if (this.busy !== response.data.busy) {
              this.busy = response.data.busy;
              this.dispatch({busy: this.busy});
            }
          }
          return response.data.busy;
        })
        .catch(() => {
          this.busy = true;
          this.dispatch({busy: this.busy});
        });
    }, 15000); // check for busy every 15 sec

    this.version = $http({
      method: 'GET',
      url: '/api/version',
    })
      .then((response) => {
        if (response && response.data) {
          return response.data.version;
        }
        return 'N/A';
      })
      .catch(() => {
        return 'N/A';
      });

    this.logFields = [];
    $http({
      method: 'GET',
      url: '/api/v3/ou/logfields',
    })
      .then((response) => {
        this.logFields = response.data;
      })
      .catch((response) => {
        $log.error('Could not fetch log fields. ', response);
      });
    this.externalKeys = [];
    $http({
      method: 'GET',
      url: '/api/v3/ou/externalkeys',
    })
      .then((response) => {
        this.externalKeys = response.data;
      })
      .catch((response) => {
        $log.error('Could not fetch external keys', response);
      });
  }

  getLogFields() {
    return this.logFields.slice();
  }

  getExternalKeys() {
    return this.externalKeys.slice();
  }

  getVersion() {
    return this.version;
  }

  getBusy() {
    return this.busy;
  }

  resetDB() {
    return this.http({
      method: 'GET',
      url: '/api/v3/backup/reset',
    }).then(() => {
      this.busy = true;
      this.dispatch({busy: true});
    });
  }

  subscribe(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function');
    }

    this.subs.push(listener);
    return function unsubscribe() {
      this.subs.splice(this.subs.indexOf(listener));
    };
  }

  dispatch(info) {
    for (let i = 0; i < this.subs.length; i++) {
      this.subs[i](info);
    }
  }
}
