import {getDateString, setDateFromString} from '../lib/utils';

export default function Overview() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: OverviewController,
    template: require('./overview.html'),
    scope: {},
  };
}

class OverviewController {
  constructor(
    $scope,
    $state,
    $rootScope,
    $log,
    $mdToast,
    HierarchyService,
    token
  ) {
    this.date = $state.params.date ? new Date($state.params.date) : new Date();
    this.admin = $rootScope.ausr;
    this.hierarchyService = HierarchyService;
    this.toast = $mdToast;
    this.token = token;
    this.log = $log;
    this.rootScope = $rootScope;

    const loadHierarchy = () => {
      HierarchyService.getFull(getDateString(this.date)).then((hierarchy) => {
        if (!hierarchy || !hierarchy.ous) {
          this.rootNodes = [];
          return;
        }
        const map = hierarchy.ous.reduce((m, o) => {
          m[o.ouId] = o;
          return m;
        }, {});
        const tree = [];
        hierarchy.ous.forEach((ou) => {
          const parent = map[ou.parent];
          if (parent) {
            parent.$children = parent.$children || [];
            parent.$children.push(ou);
          } else {
            tree.push(ou);
          }
        });
        if (tree.length !== 1) {
          $log.info(`Got ${tree.length} root nodes`);
        }
        this.rootNodes = tree;
      });
    };

    $rootScope.$watch('busy', (busy) => {
      if (busy === false) {
        loadHierarchy();
      }
    });

    $scope.$watch('ctrl.date', (newVal, oldVal) => {
      const newDate = getDateString(newVal);
      const oldDate = getDateString(oldVal);
      $state.transitionTo(
        'overview',
        {date: newDate},
        {notify: false, reload: false, inherit: false}
      );
      if (newDate === oldDate) {
        loadHierarchy();
      }
    });
    HierarchyService.getChanges().then((changes) => {
      this.changes = changes;
    });
  }

  showChange(change) {
    this.date = setDateFromString(change.validFrom);
  }

  removeChange(change) {
    this.hierarchyService
      .removeChange(change)
      .then((changes) => {
        this.changes = changes;
      })
      .catch((err) => {
        const msg =
          err.data && err.data.message ? err.data.message : err.statusText;
        this.toast.show(
          this.toast
            .simple()
            .textContent(msg)
            .hideDelay(5000)
        );
      });
  }
  login() {
    window.location.href = '/login';
  }
}
