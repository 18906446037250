import {getDateString} from '../lib/utils';

export default function Cr() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: CrController,
    template: require('./cr.html'),
    scope: {},
  };
}

class CrController {
  constructor($scope, $state, token, ChangeRequestService, $rootScope) {
    const ctrl = this;
    this.$state = $state;
    this.dzKind = {E: 'EDIT', N: 'NEW', D: 'DELETE'};
    this.ChangeRequestService = ChangeRequestService;
    ctrl.rootScope = $rootScope;
    ctrl.admin = $rootScope.ausr;
    this.dropzoneConfig = {
      options: {
        url: '/api/v3/cr/upload',
        paramName: 'csvfile',
        maxFiles: 1,
        acceptedFiles: '.csv,.xlsx',
        headers: {
          'x-client-token': token,
        },
        maxfilesexceeded: function(file) {
          this.removeAllFiles();
          this.addFile(file);
        },
      },
      eventHandlers: {
        success: function(file, resp) {
          $scope.$apply(() => {
            ctrl.dzData = resp;

            ctrl.dzData.validationResults.forEach((ou) => {
              ou.errors = ou.results.filter((item) => {
                return !!item.error;
              });
              ou.warnings = ou.results.filter((item) => {
                return !!item.warning;
              });
            });

            ctrl.errors = ctrl.dzData.validationResults.filter(
              (ou) => ou.errors.length > 0
            );
            ctrl.warnings = ctrl.dzData.validationResults.filter(
              (ou) => ou.warnings.length > 0
            );
          });
        },
      },
    };
  }

  save() {
    const data = Object.assign({}, this.dzData, {
      comment: this.comment,
      date: this.date,
    });
    this.ChangeRequestService.saveAsChangeRequest(data).then(() => {
      this.$state.go('overview', {
        date: getDateString(this.date),
      });
    });
  }
}
