import './import.scss';
import {getDateString} from '../lib/utils';
import locks from 'locks';
import angular from 'angular';

const mutex = locks.createMutex();

export default function Import() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: ImportController,
    template: require('./import.html'),
    scope: {},
  };
}

class ImportController {
  constructor($scope, $state, token, ImportService, $rootScope, $log, $http) {
    const ctrl = this;
    this.$log = $log;
    this.$http = $http;
    this.$scope = $scope;
    this.$state = $state;
    this.dzKind = {E: 'EDIT', N: 'NEW', D: 'DELETE'};
    this.ImportService = ImportService;
    this.date = new Date();
    this.token = token;
    this.changefile = undefined;
    ctrl.rootScope = $rootScope;
    ctrl.admin = $rootScope.ausr;

    $rootScope.$watch('changefile', (changefile) => {
      this.changefile = changefile;
    });
  }

  uploadFile() {
    const ctrl = this;
    const fd = new FormData();
    fd.append('file', ctrl.changefile);
    fd.append('comment', ctrl.comment);
    fd.append('date', getDateString(ctrl.date));

    this.$http
      .post('/api/v3/ou/upload', fd, {
        transformRequest: angular.identity,
        headers: {
          'x-client-token': ctrl.token,
          'Content-Type': undefined,
        },
      })
      .then(function(res) {
        ctrl.rootScope.changefile = undefined;
        ctrl.changefile = undefined;
        ctrl.dzData = res.data;

        ctrl.dzData.validationResults.forEach((ou) => {
          ou.errors = ou.results.filter((item) => {
            return !!item.error;
          });
          ou.warnings = ou.results.filter((item) => {
            return !!item.warning;
          });
        });

        ctrl.errors = ctrl.dzData.validationResults.filter(
          (ou) => ou.errors.length > 0
        );
        ctrl.warnings = ctrl.dzData.validationResults.filter(
          (ou) => ou.warnings.length > 0
        );

        ctrl.ImportService.getLatestCommit().then((date) => {
          if (date instanceof Date) {
            date.setDate(date.getDate() + 1);
          } else {
            date = new Date();
          }
          ctrl.minDate = date;
          if (!ctrl.date || ctrl.date < date) {
            ctrl.date = date;
          }
        });
      })
      .catch(function(error) {
        ctrl.$log.error(error);
      });
  }

  import() {
    if (mutex.tryLock()) {
      this.ImportService.postData(this.dzData)
        .then(() => {
          this.uploadError = undefined;
          this.$state.go('overview', {
            date: getDateString(this.date),
          });
        })
        .catch((e) => {
          this.uploadError = e;
        })
        .finally(() => {
          mutex.unlock();
        });
    } else {
      return;
    }
  }
}
