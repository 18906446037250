import './search.scss';
import {getDateString, setDateFromString} from '../lib/utils';

export default function Crlist() {
  return {
    restrict: 'E',
    controllerAs: 'searchCtrl',
    controller: SearchController,
    template: require('./search.html'),
    scope: {},
    bindToController: true,
  };
}

class SearchController {
  constructor(
    $scope,
    $state,
    $log,
    ChangeRequestService,
    $rootScope,
    token,
    HierarchyService
  ) {
    this.ctrl = this;
    this.log = $log;
    this.state = $state;
    this.isAdmin = $rootScope.ausr;
    this.token = token;
    this.changeRequestService = ChangeRequestService;
    this.statii = ['pending', 'imported', 'discarded'];
    this.status = '';
    this.searchResults = [];
    HierarchyService.getChanges().then((changes) => {
      this.changes = changes;
    });
  }

  search() {
    const theCtrl = this.ctrl;
    this.changeRequestService
      .getChangesByStatus(this.status)
      .then((results) => {
        theCtrl.searchResults = results;
      });
  }

  showOverviewChange(change) {
    this.date = setDateFromString(change.validFrom);
    this.state.transitionTo(
      'overview',
      {date: this.date},
      {notify: false, reload: false, inherit: false}
    );
  }

  createdDate(stamp) {
    return getDateString(new Date(stamp));
  }

  showChange(theChange) {
    this.changeRequestService
      .getChangeRequest(theChange.result.id)
      .then((response) => {
        this.changeRequestService.uploadCsv(response.csv).then((resp) => {
          this.state.transitionTo('pending', {
            data: resp,
            id: theChange.result.id,
            status: theChange.result.status,
            previous: 'search',
          });
        });
      });
  }
}
