import angular from 'angular';
import './hierarchy.scss';
import {getDateString} from '../lib/utils';

export default function Hierarchy() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: HierarchyController,
    template: require('./hierarchy.html'),
    scope: {
      ou: '=',
    },
    bindToController: true,
  };
}

class HierarchyController {
  constructor($log, $mdDialog, OuService, AppService) {
    this.logFields = AppService.getLogFields();
    this.externalKeys = AppService.getExternalKeys();
    this.$dialog = $mdDialog;
    this.ouService = OuService;
    this.carets = {};
    this.logger = $log;
  }

  getCaret(id) {
    if (this.carets[id]) {
      return 'expand_more';
    }

    return 'expand_less';
  }

  toggle(id) {
    this.carets[id] = !this.carets[id];
  }

  showOu(ou) {
    delete ou.$$hashKey;
    this.ouService.getLog(ou.ouId).then((log) => {
      ou = filterizeOu({ou}, this.logFields);
      log = filterizeOu(log, this.logFields);
      this.$dialog.show({
        controller: DialogController,
        controllerAs: 'dialogCtrl',
        template: require('./hierarchy.oudialog.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {ou, log},
      });
    });
  }
}

class DialogController {
  constructor(ou, log, $mdDialog, $rootScope, OuService, AppService) {
    this.logFields = AppService.getLogFields();
    this.externalKeys = AppService.getExternalKeys();
    this.ouService = OuService;
    this.originalOu = null;
    this.originalLog = null;
    this.ou = angular.copy(ou);
    this.log = angular.copy(log);
    this.keyList = asKeyList(log);
    this.admin = $rootScope.ausr;
    this.newKey = {};
    this.error = null;
    delete this.ou.$children;
    delete this.log.$children;
  }

  makeList(predecessors) {
    return predecessors.split(',');
  }

  kind(key) {
    const map = {E: 'EDIT', N: 'NEW', D: 'DELETE'};
    return map[key];
  }

  closePredecessorView() {
    this.ou = angular.copy(this.originalOu);
    this.log = angular.copy(this.originalLog);
    this.keyList = angular.copy(this.originalKeyList);
    this.originalOu = null;
  }

  removeKey(key) {
    this.ouService
      .deleteKey(this.log.ou.ouId, key.keyType, key.value, key.validFrom)
      .then(() => this.ouService.getLog(this.log.ou.ouId))
      .then((log) => {
        delete this.error;
        delete this.originalOu;
        delete this.originalLog;
        const filterizedOu = filterizeOu(log, this.logFields);
        this.log = angular.copy(filterizedOu);
        this.ou = angular.copy(filterizedOu);
        this.keyList = asKeyList(this.log);
      })
      .catch((err) => {
        if (err.data) {
          this.error = err.data.message;
        } else {
          this.error = err.message;
        }
      });
  }

  addKey() {
    for (const attr of ['validFrom', 'keyType', 'value']) {
      if (!this.newKey || !this.newKey[attr]) {
        this.error = `${attr} is required`;
        return;
      }
    }
    const validFrom = getDateString(this.newKey.validFrom);
    const validTo = getDateString(this.newKey.validTo);

    this.ouService
      .putKey(
        this.ou.ou.ouId,
        this.newKey.keyType,
        this.newKey.value,
        validFrom,
        validTo
      )
      .then(() => this.ouService.getLog(this.ou.ou.ouId))
      .then((log) => {
        delete this.error;
        delete this.originalOu;
        delete this.originalLog;
        const filterizedOu = filterizeOu(log, this.logFields);
        this.log = angular.copy(filterizedOu);
        this.ou = angular.copy(filterizedOu);
        this.keyList = asKeyList(this.log);
      })
      .catch((err) => {
        if (err.data) {
          this.error = err.data.message;
        } else {
          this.error = err.message;
        }
      });
  }

  showPredecessor(id) {
    this.ouService.getLog(id).then((log) => {
      delete this.error;
      if (!this.originalOu) {
        this.originalOu = angular.copy(this.ou);
        this.originalLog = angular.copy(this.log);
        this.originalKeyList = angular.copy(this.keyList);
      }
      const filterizedOu = filterizeOu(log, this.logFields);
      this.log = angular.copy(filterizedOu);
      this.ou = angular.copy(filterizedOu);
      this.keyList = asKeyList(this.log);
    });
  }
}

function asKeyList(log) {
  const keyMap = {};
  for (const {type, value, sourceSystem} of log.ou.externalKeys) {
    const keyType = `${type}${sourceSystem ? '@' + sourceSystem : ''}`;
    const keyKey = `${keyType}:${value}`;
    keyMap[keyKey] = keyMap[keyKey] || [];
    keyMap[keyKey].push({validFrom: log.ou.validFrom});
  }
  for (const [date, changes] of Object.entries(log.diffs)) {
    for (const item of changes) {
      if (item.path[0] !== 'externalKeys') {
        continue;
      }
      if (item.kind === 'D') {
        if (!keyMap[item.lhs]) {
          // TODO: display error
          continue;
        }
        const timeFrame = keyMap[item.lhs].pop();
        timeFrame.validTo = date;
        keyMap[item.lhs].push(timeFrame);
      } else {
        keyMap[item.rhs] = keyMap[item.rhs] || [];
        keyMap[item.rhs].push({validFrom: date});
      }
    }
  }
  const res = [];
  for (const key in keyMap) {
    const timeFrames = keyMap[key],
      [keyType, value] = key.split(':');
    for (const timeFrame of timeFrames) {
      res.push({
        validFrom: timeFrame.validFrom,
        validTo: timeFrame.validTo,
        keyType,
        value,
      });
    }
  }
  return res.sort((a, b) => {
    return (
      a.validFrom.localeCompare(b.validFrom) ||
      a.value.localeCompare(b.value) ||
      a.keyType.localeCompare(b.keyType)
    );
  });
}

function filterizeOu(ou, logFields) {
  const filtered = [];
  function filterOu(prefix, ou, filtered) {
    Object.keys(ou).forEach((key) => {
      const path = prefix ? `${prefix}.${key}` : key;
      if (key === 'externalKeys') {
        return;
      }
      if (key === '$children') {
        return;
      }
      switch (typeof ou[key]) {
        case 'string':
        case 'number':
          return filtered.push({key: path, value: ou[key]});
        case 'object':
          if (Object.prototype.toString.call(ou[key]) === '[object Array]') {
            return filtered.push({key: path, value: ou[key].join(', ')});
          }
          return filterOu(path, ou[key], filtered);
        default:
          console.warn({key, path, type: typeof ou[key]});
      }
    });
  }
  filterOu(null, ou.ou, filtered);
  ou.filtered = sortByLogFields(filtered, logFields);
  return ou;
}

const sortByLogFields = (ouEntries, logFields) => {
  const knownFields = [];
  const otherFields = [];
  for (const item of ouEntries) {
    if (logFields.includes(item.key)) {
      knownFields.push(item);
    } else {
      otherFields.push(item);
    }
  }
  knownFields.sort(
    (a, b) => logFields.indexOf(a.key) - logFields.indexOf(b.key)
  );
  otherFields.sort(
    (a, b) =>
      String(a.key).localeCompare(String(b.key)) ||
      String(a.value).localeCompare(String(b.value))
  );
  return knownFields.concat(otherFields);
};
