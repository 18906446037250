// styles
import 'angular-material/angular-material.css';

// modules
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularAnimate from 'angular-animate';
import angularMaterial from 'angular-material';

// config
import config from './config';

// filters
import asyncFilter from './filter/async';

// services
import HierarchyService from './service/hierarchy';
import ImportService from './service/import';
import OuService from './service/ou';
import AppService from './service/app';
import ChangeRequestService from './service/changeRequest';

// directives
import filemodel from './directive/filemodel';

// pages
import OverviewPage from './page/overview';
import ImportPage from './page/import';
import CrPage from './page/cr';
import PendingPage from './page/pending';
import SearchPage from './page/search';

//components
import Toolbar from './component/toolbar';
import Hierarchy from './component/hierarchy';
import Changelist from './component/changelist';
import Crlist from './component/crlist';

export default angular
  .module('App', [uiRouter, angularAnimate, angularMaterial])
  .config(config)
  .filter('async', asyncFilter)
  .service('HierarchyService', HierarchyService)
  .service('ImportService', ImportService)
  .service('OuService', OuService)
  .service('AppService', AppService)
  .service('ChangeRequestService', ChangeRequestService)
  .directive('fileModel', filemodel)
  .directive('pageOverview', OverviewPage)
  .directive('pageImport', ImportPage)
  .directive('pageCr', CrPage)
  .directive('pagePending', PendingPage)
  .directive('pageSearch', SearchPage)
  .directive('toolbar', Toolbar)
  .directive('hierarchy', Hierarchy)
  .directive('changelist', Changelist)
  .directive('crlist', Crlist)
  .run(($rootScope, AppService) => {
    AppService.subscribe((info) => {
      $rootScope.busy = info.busy;
    });
  }).name;
