/* -*- coding: utf-8 -*-
 * vim: et:sw=4:sts=4
 */
/**
 * Copyright(c) 2020 Norwegian University of Science and Technology
 *
 */

const getDateString = (dateObj) => {
  // Format: 'YYYY-MM-DD'
  return isValidDate(dateObj)
    ? [
        String(dateObj.getFullYear()).padStart(4, '0'),
        String(dateObj.getMonth() + 1).padStart(2, '0'),
        String(dateObj.getDate()).padStart(2, '0'),
      ].join('-')
    : '';
};

const getTimeString = (dateObj) => {
  // Format: 'hh:mm:ss'
  return isValidDate(dateObj)
    ? [
        String(dateObj.getHours()).padStart(2, '0'),
        String(dateObj.getMinutes()).padStart(2, '0'),
        String(dateObj.getSeconds()).padStart(2, '0'),
      ].join(':')
    : '';
};

const getDateAndTimeStr = (dateObj) => {
  // Format: 'YYYY-MM-DD hh:mm:ss'
  return isValidDate(dateObj)
    ? getDateString(dateObj) + ' ' + getTimeString(dateObj)
    : '';
};

const isValidDateString = (dateStr) => {
  // Supported dateStr format: YYYY-MM-DD
  const dateParams = dateStr.split('-');
  const dateObj = new Date(+dateParams[0], +dateParams[1] - 1, +dateParams[2]);
  return (
    isValidDate(dateObj) &&
    dateObj.getMonth() + 1 === +dateParams[1] &&
    dateObj.getDate() === +dateParams[2]
  );
};

const isValidDate = (dateObj) =>
  dateObj instanceof Date && Number.isFinite(dateObj.valueOf());

const setDateFromString = (dateStr) => {
  // Supported dateStr format: YYYY-MM-DD
  const dateParams = dateStr.split('-');
  return isValidDateString(dateStr)
    ? new Date(+dateParams[0], +dateParams[1] - 1, +dateParams[2])
    : new Date(NaN);
};

export {
  getDateString,
  getTimeString,
  getDateAndTimeStr,
  setDateFromString,
  isValidDateString,
  isValidDate,
};
