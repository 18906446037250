export default class ImportService {
  constructor($http, $log) {
    this.http = $http;
    this.log = $log;
  }

  getLatestCommit() {
    return this.http({method: 'GET', url: '/api/v3/commit'}).then(
      (res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          return new Date(res.data[0].validFrom);
        }
        return null;
      },
      (err) => {
        this.log.error(err.message);
      }
    );
  }

  postData(data) {
    return this.http({
      method: 'POST',
      url: `/api/v3/ou/`,
      data: data,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        this.log.warn('invalid response');
        return {};
      })
      .catch((err) => {
        this.log.error(err);
        throw err;
      });
  }
}
