export default function Toolbar() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: ToolbarCtrl,
    template: require('./toolbar.html'),
    scope: {},
  };
}

class ToolbarCtrl {
  constructor(
    token,
    canReset,
    OuService,
    AppService,
    $rootScope,
    $mdDialog,
    $mdToast
  ) {
    this.reset = canReset;
    this.token = token;
    this.ouService = OuService;
    this.appService = AppService;
    this.admin = $rootScope.ausr;
    this.mdDialog = $mdDialog;
    this.toast = $mdToast;
  }
  nextOuId() {
    return this.ouService.getNextOuId();
  }
  version() {
    return this.appService.getVersion();
  }
  login() {
    window.location.href = '/login';
  }
  logout() {
    window.location.href = '/logout';
  }
  resetDB(ev) {
    const confirm = this.mdDialog
      .confirm()
      .title('Would you like to reset the database?')
      .textContent(
        'This will reset the database to the state of the production server.'
      )
      .ariaLabel('Reset database')
      .targetEvent(ev)
      .ok('Sure, nuke it')
      .cancel('Cancel');

    this.mdDialog.show(confirm).then(
      () => {
        this.appService
          .resetDB()
          .then(() => {
            this.toast.show(
              this.toast
                .simple()
                .textContent('DB has been reset!')
                .hideDelay(5000)
            );
          })
          .catch((err) => {
            this.toast.show(
              this.toast
                .simple()
                .textContent(
                  (err.data && err.data.error) ||
                    err.message ||
                    err.statusText ||
                    'Reset failed'
                )
                .hideDelay(5000)
            );
          });
      },
      function() {}
    );
  }
}
