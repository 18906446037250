export default function filemodel($parse, $rootScope) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs, controllers) {
      const model = $parse(attrs.fileModel);
      const modelSetter = model.assign;

      element.bind('change', function() {
        scope.$apply(function() {
          // nasty hack — yeah yeah
          modelSetter($rootScope, element[0].files[0]);
        });
      });
    },
  };
}
