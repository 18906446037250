import {getDateString, setDateFromString} from './lib/utils';

export default function config(
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $mdThemingProvider,
  $httpProvider,
  $provide,
  $mdDateLocaleProvider
) {
  // Disable caching on http requests.
  $httpProvider.defaults.headers.get = $httpProvider.defaults.headers.get || {};
  $httpProvider.defaults.headers.get['If-Modified-Since'] =
    'Mon, 26 Jul 1997 05:00:00 GMT';
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get.Pragma = 'no-cache';
  $locationProvider.html5Mode(true);
  $mdDateLocaleProvider.firstDayOfWeek = 1;
  $mdDateLocaleProvider.parseDate = (dateString) =>
    setDateFromString(dateString);
  $mdDateLocaleProvider.formatDate = (date) => getDateString(date);
  // material theme
  $mdThemingProvider
    .theme('default')
    .primaryPalette('indigo')
    .accentPalette('blue');

  const token = document.body.getAttribute('data-token');
  const canReset = document.body.getAttribute('data-reset') === 'true';
  $provide.value('token', token);
  $provide.value('canReset', canReset);

  $httpProvider.interceptors.push(function($q, $location, $log, $rootScope) {
    return {
      'request': function(config) {
        // intercept and change config: e.g. change the URL
        // config.url += '?nocache=' + (new Date()).getTime();
        // broadcasting 'httpRequest' event
        $rootScope.$broadcast('httpRequest', config);
        if (token) {
          config.headers['x-client-token'] = token;
        }
        return config || $q.when(config);
      },
      'response': function(response) {
        // we can intercept and change response here...
        // broadcasting 'httpResponse' event
        $rootScope.$broadcast('httpResponse', response);
        return response || $q.when(response);
      },
      'requestError': function(rejection) {
        // broadcasting 'httpRequestError' event
        $rootScope.$broadcast('httpRequestError', rejection);
        return $q.reject(rejection);
      },
      'responseError': function(rejection) {
        // broadcasting 'httpResponseError' event
        $rootScope.$broadcast('httpResponseError', rejection);
        return $q.reject(rejection);
      },
    };
  });

  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('overview', {
      url: '/?date',
      template: '<page-overview></page-overview>',
    })
    .state('import', {
      url: '/import',
      templateProvider: AuthTemplateProvider('<page-import></page-import>'),
    })
    .state('cr', {
      url: '/cr',
      template: '<page-cr></page-cr>',
    })
    .state('pending', {
      url: '/pending',
      params: {data: null, id: null, status: null, previous: null},
      template: '<page-pending></page-pending>',
    })
    .state('search', {
      url: '/search',
      template: '<page-search></page-search>',
    });
}

function AuthTemplateProvider(template) {
  return function($rootScope) {
    if ($rootScope.ausr) {
      return template;
    }
    return '<p>Not authorized.</p><p><a ui-sref="overview">Start page</a></p>';
  };
}
