export default class OuService {
  constructor($http, $log) {
    this.http = $http;
    this.log = $log;
    this.lastFetch = null;
    this.nextOuIdPromise = null;
  }

  getNextOuId() {
    // Use the same OuId if less than two minutes have passed since lastFetch.
    // (Seems scary...)
    if (this.lastFetch && Date.now() - 2 * 60e3 < this.lastFetch) {
      return this.nextOuIdPromise;
    }
    this.lastFetch = new Date();
    this.nextOuIdPromise = this.http({
      method: 'GET',
      url: `/api/v3/ou/nextid`,
    })
      .then((response) => {
        if (response && response.data) {
          return response.data.nextid;
        }

        this.log.warn('invalid response');
        return -1;
      })
      .catch((err) => {
        this.log.error(err);
        return -2;
      });
    return this.nextOuIdPromise;
  }

  getLog(id) {
    return this.http({method: 'GET', url: `/api/v3/ou/${id}/log`}).then(
      (response) => {
        return response.data;
      }
    );
  }

  deleteKey(id, keyType, keyValue, validFrom) {
    const url = `/api/v3/ou/${id}/key/${keyType}/${keyValue}/${validFrom}`;
    return this.http({method: 'DELETE', url}).then(
      (response) => {
        return response.data;
      },
      (err) => {
        throw err;
      }
    );
  }
  putKey(id, keyType, keyValue, validFrom, validTo) {
    const url = `/api/v3/ou/${id}/key/${keyType}/${keyValue}/${validFrom}/${validTo}`;
    return this.http({method: 'PUT', url}).then(
      (response) => {
        return response.data;
      },
      (err) => {
        throw err;
      }
    );
  }
}
