import './list.scss';
import {setDateFromString} from '../lib/utils';

export default function Changelist() {
  return {
    restrict: 'E',
    controllerAs: 'ctrl',
    controller: ChangelistController,
    template: require('./changelist.html'),
    scope: {
      changes: '=',
      selectFn: '&',
      removeFn: '&',
    },
    bindToController: true,
  };
}

class ChangelistController {
  constructor($rootScope) {
    this.isAdmin = $rootScope.ausr;
  }

  canDelete(change, index) {
    if (index !== 0 || !this.isAdmin) {
      return false;
    }
    // Future date?
    return setDateFromString(change.validFrom).valueOf() > Date.now();
  }
}
