import './list.scss';
import {getDateString} from '../lib/utils';

export default function Crlist() {
  return {
    restrict: 'E',
    controllerAs: 'CrlistCtrl',
    controller: CrlistController,
    template: require('./crlist.html'),
    scope: {},
    bindToController: true,
  };
}

class CrlistController {
  constructor($scope, $state, $log, ChangeRequestService, token, $rootScope) {
    this.ctrl = this;
    this.log = $log;
    this.scope = $scope;
    this.state = $state;
    this.isAdmin = $rootScope.ausr;
    this.changeRequestService = ChangeRequestService;
    this.dropzoneConfig = {
      options: {
        url: '/api/v3/ou/upload',
        paramName: 'csvfile',
        maxFiles: 1,
        acceptedFiles: '.csv,.xlsx',
        headers: {
          'x-client-token': token,
        },
        maxfilesexceeded: function(file) {
          this.removeAllFiles();
          this.addFile(file);
        },
      },
    };
    ChangeRequestService.getChangesByStatus('pending').then(
      (changeRequests) => {
        this.ctrl.pendingRequests = changeRequests;
      }
    );
  }

  showChange(pendingRequest) {
    this.changeRequestService
      .getChangeRequest(pendingRequest.pending.id)
      .then((pendingResp) => {
        this.changeRequestService.uploadCsv(pendingResp.csv).then((resp) => {
          this.state.transitionTo('pending', {
            data: resp,
            id: pendingRequest.pending.id,
            previous: 'overview',
          });
        });
      })
      .catch((err) => {
        const msg =
          err.data && err.data.message ? err.data.message : err.statusText;
        this.toast.show(
          this.toast
            .simple()
            .textContent(msg)
            .hideDelay(5000)
        );
      });
  }

  canDelete() {
    return this.isAdmin;
  }

  createdDate(stamp) {
    return getDateString(new Date(stamp));
  }

  discard(pendingRequest) {
    const ctrl = this.ctrl;
    this.changeRequestService
      .setDiscarded(pendingRequest.pending.id)
      .then(() => {
        this.changeRequestService
          .getChangesByStatus('pending')
          .then((changeRequests) => {
            ctrl.pendingRequests = changeRequests;
          });
      })
      .catch((err) => {
        const msg =
          err.data && err.data.message ? err.data.message : err.statusText;
        this.toast.show(
          this.toast
            .simple()
            .textContent(msg)
            .hideDelay(5000)
        );
      });
  }
}
